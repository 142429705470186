import React from "react"

import { Helmet } from "react-helmet"

const htmlAttributes = {
  lang: "ru",
}
const defTitle = "Специальное предложение по восстановлению кредитной истории"
const defDescription =
  "Поможем улучшить кредитную историю: вы сможете получить одобрение на кредит на крупную сумму"
const author = "@sovcombank"

export type SEOProps = {
  title?: string
  description?: string
}

export function SEO({ title = defTitle, description = defDescription }: SEOProps) {
  const meta = [
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: author,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "description",
      content: description,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "format-detection",
      content: "telephone=no",
    },
  ]

  return <Helmet htmlAttributes={htmlAttributes} title={title} meta={meta} />
}
